<template>
  <div class="wxSecurity">
    <div class="wxVideoTitle" v-show="projectOptions.length > 0">
      <div class="wxVideoTitleInput" @click="showWxVideoTitleItemFunction">
        <div>{{ wxVideoTitleInputName }}</div>
        <div class="wxVideoTitleInputIcon">
          <i v-show="!showWxVideoTitleItem" class="el-icon-caret-bottom"></i>
          <i v-show="showWxVideoTitleItem" class="el-icon-caret-top"></i>
        </div>
      </div>
    </div>
    <div class="wxVideoTitleItem" v-show="showWxVideoTitleItem">
      <div class="itemTextLiBox">
        <div class="itemTextLi">
          <div class="itemText" v-for="(item, index) in projectOptions" :key="index"
            :class="{ active: wxVideoTitleInputName == item.label }" @click="ProjectSwitching(item)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>

    <div class="wxhumanResourceAnalysisCentent" v-show="actionName == '进场总人数'">
      <div class="cententBox">
        <div class="cententBoxTitle">
          <div class="cententBoxTitleText">开始时间:</div>
          <div class="cententBoxTitleInput">
            <el-date-picker v-model="TotalNumberofpeople.startTime" clearable type="date" placeholder="选择开始日期"
              size="mini" value-format="yyyy-MM-dd" @change="searchFunc('1')">
            </el-date-picker>
          </div>
        </div>
        <div class="cententBoxTitle">
          <div class="cententBoxTitleText">结束时间:</div>
          <div class="cententBoxTitleInput">
            <el-date-picker v-model="TotalNumberofpeople.endTime" clearable type="date" placeholder="选择结束日期" size="mini"
              value-format="yyyy-MM-dd" @change="searchFunc('1')">
            </el-date-picker>
          </div>
        </div>
        <div class="cententTabs">
          <div class="cententTabsItem" v-for="item in tabsList" :key="item.name"
            :class="{ tabsAction: TotalNumberofpeople.activeName == item.name }"
            @click="TotalNumberofpeople.activeName = item.name">
            {{ item.name }}
          </div>
        </div>
        <div class="cententBoxContent" v-show="TotalNumberofpeople.activeName == '统计图'">
          <div class="cententBoxContentText">
            {{ TotalNumberofpeople.startTime }} ~
            {{ TotalNumberofpeople.endTime }} 进场总人数统计
          </div>
          <div id="div_mobilizationTotalNumber"></div>
        </div>
        <div class="cententBoxContent" v-show="TotalNumberofpeople.activeName == '统计表'">
          <div class="tableCentent">
            <el-table :data="mobilizationTotalNumberData" style="width: 100%" border :height="300">
              <el-table-column type="index" width="60" label="序号">
              </el-table-column>
              <el-table-column prop="label" label="单位名称" align="center">
              </el-table-column>
              <el-table-column prop="value" label="进场人数" align="center" width="100">
              </el-table-column>
            </el-table>
          </div>

        </div>
      </div>
    </div>
    <div class="wxhumanResourceAnalysisCentent" v-show="actionName == '工作投入分布'">
      <div class="cententBox">
        <div class="cententBoxTitle">
          <div class="cententBoxTitleText">开始时间:</div>
          <div class="cententBoxTitleInput">
            <el-date-picker v-model="Jobtypes.startTime" clearable type="date" placeholder="选择开始日期" size="mini"
              value-format="yyyy-MM-dd" @change="searchFunc('2')">
            </el-date-picker>
          </div>
        </div>
        <div class="cententBoxTitle">
          <div class="cententBoxTitleText">结束时间:</div>
          <div class="cententBoxTitleInput">
            <el-date-picker v-model="Jobtypes.endTime" clearable type="date" placeholder="选择结束日期" size="mini"
              value-format="yyyy-MM-dd" @change="searchFunc('2')">
            </el-date-picker>
          </div>
        </div>
        <div class="cententTabs">
          <div class="cententTabsItem" v-for="item in tabsList" :key="item.name"
            :class="{ tabsAction: Jobtypes.activeName == item.name }" @click="Jobtypes.activeName = item.name">
            {{ item.name }}
          </div>
        </div>
        <div class="cententBoxContent" v-show="Jobtypes.activeName == '统计图'">
          <div class="cententBoxContentText">
            {{ Jobtypes.startTime }} ~
            {{ Jobtypes.endTime }} 进场总人数统计
          </div>
          <div id="div_postInputDistribution"></div>
        </div>
        <div class="cententBoxContent" v-show="Jobtypes.activeName == '统计表'">
          <div class="tableCentent">
            <el-table :data="postInputDistributionData" style="width: 100%" border :height="300">
              <el-table-column type="index" width="60" label="序号">
              </el-table-column>
              <el-table-column prop="label" label="单位名称" align="center">
              </el-table-column>
              <el-table-column prop="value" label="进场人数" align="center" width="100">
              </el-table-column>
            </el-table>
          </div>

        </div>
      </div>
    </div>
    <div class="wxhumanResourceAnalysisCentent" v-show="actionName == '劳动力曲线分析'">
      <div class="cententBox">
        <div class="cententBoxTitle">
          <div class="cententBoxTitleText">筛选范围:</div>
          <div class="cententBoxTitleInput">
            <el-select v-model="laborforce.Range" clearable placeholder="请选择" @change="changeRange">
              <el-option v-for="item in laborforceOptions" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cententBoxTitle">
          <div class="cententBoxTitleText">进场日期:</div>
          <div class="cententBoxTitleInput">
            <el-date-picker v-model="laborforce.startTime" clearable :type="laborforce.datepickerType"
              placeholder="选择时间" @change="searchFunc('3')">
            </el-date-picker>
          </div>
        </div>
        <div class="cententBoxContent">
          <div class="cententBoxContentText">
            {{ laborforce.startTime }} ~
            {{ laborforce.endTime }} 进场总人数统计
          </div>
          <div id="LDLQXecharts"></div>
        </div>
      </div>
    </div>
    <div class="wxhumanResourceAnalysisBottom">
      <div class="bottomItem" v-for="item in botList" :key="item.name">
        <div @click="actionNamefunc(item)">
          {{ item.name }}
        </div>
        <div class="bottomItemImg" v-show="item.name == actionName"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  mobilizationTotalNumber, postInputDistribution, laborForceCurve,
} from "@/api/humanResourceAnalysis/humanResourceAnalysis";
import { treePro } from "@/api/dbtComon/dbtComon";
import { dateFormat } from "@/util/date";
import { getDeptTree } from "@/api/system/dept";
export default {
  components: {},
  data() {
    return {
      botList: [
        { name: "进场总人数" },
        { name: "工作投入分布" },
        { name: "劳动力曲线分析" }
      ],
      actionName: "进场总人数",
      projectOptions: [],
      tabsList: [
        { name: "统计图" },
        { name: "统计表" },
      ],
      orgId: undefined,
      TotalNumberofpeople: {
        activeName: "统计图",
        nameInput: "",
        startTime: "",
        endTime: "",
        max: 0,
        avg: 0,
      },
      Jobtypes: {
        activeName: "统计图",
        nameInput: "",
        startTime: "",
        endTime: "",
        max: 0,
        avg: 0,
      },
      laborforce: {
        activeName: "统计图",
        nameInput: "",
        startTime: new Date().getFullYear() + "",
        endTime: "",
        Range: "年",
        datepickerType: "year",
        datepickerFormat: "yyyy",
      },
      mobilizationTotalNumberData: [],
      postInputDistributionData: [],
      ldlTableData: [],
      laborforceOptions: [
        {
          name: "年",
          value: "年",
        },
        {
          name: "月",
          value: "月",
        },
        {
          name: "日",
          value: "日",
        },
      ],
      options: [],
      wxVideoTitleInputName: "",
      showWxVideoTitleItem: false,
    };
  },
  created() { },
  mounted() {
    let that = this;
    let userInfo = that.userInfo;
    var data = [];
    treePro({ parentId: userInfo.dept_id }).then((res) => {
      if (res.data.success) {
        data = res.data.data || [];
        // data.forEach(item => {
        //   item.title = item.label.length > 14 ? item.label.substring(0, 14) : item.label;
        // })
        if (data.length == 0) {
          var company_id = userInfo.company_id || -1;
          var dept_id = userInfo.dept_id;
          that.orgId = company_id == -1 ? dept_id : company_id;
        } else {
          data.unshift({
            label: "全部项目"
          })
          that.projectOptions = data;
        }
        that.initMobilizationTotalNumber();
      }
    });
  },
  // 计算属性
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    // 底部切换方法
    actionNamefunc(row) {
      this.actionName = row.name;
      if (this.actionName == "进场总人数") {
        this.initMobilizationTotalNumber();
      }
      if (this.actionName == "工作投入分布") {
        this.initPostInputDistribution();
      }
      if (this.actionName == "劳动力曲线分析") {
        this.LDLQXecharts();
      }

    },
    searchFunc(lx) {
      let that = this;
      if (lx == "1") {
        that.initMobilizationTotalNumber();
      }
      if (lx == "2") {
        that.initPostInputDistribution();
      }
      if (lx == "3") {
        that.LDLQXecharts();
      }
    },
    showWxVideoTitleItemFunction() {
      this.showWxVideoTitleItem = !this.showWxVideoTitleItem;
    },
    ProjectSwitching(item) {
      this.wxVideoTitleInputName = item.label;
      this.orgId = item.value;
      if (this.actionName == "进场总人数") {
        this.initMobilizationTotalNumber();
      }
      if (this.actionName == "工作投入分布") {
        this.initPostInputDistribution();
      }
      if (this.actionName == "劳动力曲线分析") {
        this.LDLQXecharts();
      }
      this.showWxVideoTitleItem = false;
    },
    // 字体大小
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 统计图数据初始化
    initMobilizationTotalNumber() {
      let that = this;
      let orgId = that.orgId;
      let TotalNumberofpeople = that.TotalNumberofpeople;
      let { startTime, endTime } = TotalNumberofpeople;
      if (startTime)
        startTime = dateFormat(new Date(startTime), "yyyy-MM-dd 00:00:00");
      if (endTime)
        endTime = dateFormat(new Date(endTime), "yyyy-MM-dd 23:59:59");

      mobilizationTotalNumber({ orgId, startTime, endTime }).then((res) => {
        var dom = document.getElementById("div_mobilizationTotalNumber");
        this.$echarts.init(dom).clear();
        var myChart = this.$echarts.init(dom, null, {
          renderer: "canvas",
          useDirtyRect: false,
        });

        var data = [];
        if (res.data.success) {
          data = res.data.data;
        }
        var sum = 0;
        var xda = [];
        var yda1 = [];
        var yda2 = [];
        var option = {};
        if (data.length == 0) {
          option = that.getEmptyOption();
        } else {
          data.forEach((item) => {
            xda.push(item.label);
            sum += Number(item.value);
            yda1.push(Number(item.value));
            yda2.push(Number(item.value));
          });

          option = {
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "5%",
              bottom: "1%",
              top: "10%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: xda,
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2d96f1", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#2d96f1", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: yda1,
                name: "进场人次",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
              },
              {
                name: "Number",
                type: "line",
                // stack: 'Total',
                data: yda2,
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: "#3c72ed",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#3c72ed",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
        }
        that.TotalNumberofpeople.sum = sum;
        var max = Math.max(...yda1) == "-Infinity" ? 0 : Math.max(...yda1);
        that.TotalNumberofpeople.max = max;
        var sum = yda1.reduce((a, b) => a + b, 0);
        that.TotalNumberofpeople.avg = Math.floor(sum / yda1.length) || 0;
        that.mobilizationTotalNumberData = data;
        console.log(option);
        window.addEventListener("resize", myChart.resize());
        option && myChart.setOption(option);
      });
    },
    initPostInputDistribution() {
      let that = this;
      let orgId = that.orgId;
      let Jobtypes = that.Jobtypes;
      let { startTime, endTime } = Jobtypes;
      if (startTime)
        startTime = dateFormat(new Date(startTime), "yyyy-MM-dd 00:00:00");
      if (endTime)
        endTime = dateFormat(new Date(endTime), "yyyy-MM-dd 23:59:59");

      postInputDistribution({ orgId, startTime, endTime }).then((res) => {
        var dom = document.getElementById("div_postInputDistribution");
        this.$echarts.init(dom).clear();
        // this.$echarts.init(dom).dispose();
        var myChart = this.$echarts.init(dom);
        var data = [];
        if (res.data.success) {
          data = res.data.data;
        }
        var sum = 0;
        var xda = [];
        var yda1 = [];
        var yda2 = [];
        var option = {};
        if (data.length == 0) {
          option = that.getEmptyOption();
        } else {
          data.forEach((item) => {
            xda.push(item.label);
            sum += Number(item.value);
            yda1.push(Number(item.value));
            yda2.push(Number(item.value));
          });

          option = {
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "5%",
              bottom: "1%",
              top: "10%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: xda,
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2d96f1", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#2d96f1", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: yda1,
                name: "进场人次",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
              },
              {
                name: "Number",
                type: "line",
                // stack: 'Total',
                data: yda2,
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: "#3c72ed",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#3c72ed",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
        }
        that.TotalNumberofpeople.sum = sum;
        var max = Math.max(...yda1) == "-Infinity" ? 0 : Math.max(...yda1);
        that.TotalNumberofpeople.max = max;
        var sum = yda1.reduce((a, b) => a + b, 0);
        that.TotalNumberofpeople.avg = Math.floor(sum / yda1.length) || 0;
        that.postInputDistributionData = data;
        window.addEventListener("resize", myChart.resize());
        option && myChart.setOption(option);
      });
    },
    // 劳动力曲线
    LDLQXecharts() {
      let that = this;
      let orgId = that.orgId;
      var dom = document.getElementById("LDLQXecharts");
      this.$echarts.init(dom).clear();
      var myChart = this.$echarts.init(dom);
      console.log(that.projectOptions);
      let dataX = [];
      let data1 = [];
      let option;
      let data = {
        mode: that.laborforce.Range,
        orgId: orgId,
      };

      if (that.laborforce.Range == "年") {
        data.year = dateFormat(new Date(that.laborforce.startTime), "yyyy");
      }
      if (that.laborforce.Range == "月") {
        data.month = dateFormat(new Date(that.laborforce.startTime), "yyyy-MM");
      }
      if (that.laborforce.Range == "日") {
        data.day = dateFormat(
          new Date(that.laborforce.startTime),
          "yyyy-MM-dd"
        );
      }
      laborForceCurve(data).then((res) => {
        console.log("劳动力曲线", res);
        if (res.data.data.length == 0) {
          option = that.getEmptyOption();
          option && myChart.setOption(option);
        } else {
          res.data.data.forEach((item) => {
            dataX.push(item.label);
            data1.push(item.value);
          });
          option = {
            grid: {
              top: "10%",
              bottom: "5%",
              left: "5%",
              right: "5%",
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: dataX,
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
              axisLabel: {
                textStyle: {
                  color: "#2d96f1", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },

                formatter: function (value) {
                  var res = value;
                  if (res.length > 10) {
                    res = res.substring(0, 9) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2d96f1", //更改坐标轴颜色
                },
              },
              splitLine: {
                //去除网格线
                show: false,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#cfcfcf", //更改刻度尺颜色
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#46a2f3", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#46a2f3", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                show: true, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                name: "平均人次",
                type: "line",
                // stack: 'Total',
                data: data1,
                symbol: "circle",
                symbolSize: 5, //指示点大小
                smooth: 0.5, //弯曲程度
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: "red",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "red",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        }
      });
    },
    changeRange() {
      if (this.laborforce.Range == "年") {
        this.laborforce.datepickerType = "year";
        this.laborforce.datepickerFormat = "yyyy";
      }
      if (this.laborforce.Range == "月") {
        this.laborforce.datepickerType = "month";
        this.laborforce.datepickerFormat = "MM";
      }
      if (this.laborforce.Range == "日") {
        this.laborforce.datepickerType = "date";
        this.laborforce.datepickerFormat = "yyyy-MM-dd";
      }
      this.laborforce.startTime = "";
    },

    // 空白option
    getEmptyOption() {
      return {
        title: [
          {
            text: "{A|" + "" + "}",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: "35",
              color: "#FFF",
              lineHieght: 150,
              rich: {
                A: {
                  backgroundColor: {
                    image: require("../../../assets/empty.png"),
                  },
                  height: 300,
                  width: 300,
                },
              },
            },
          },
        ],
      };
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  padding: 1vh;
}

body .wxSecurity {
  width: 100vw;
  height: 100vh;

  position: relative;
}

.wxhumanResourceAnalysisCentent {
  width: 100%;
  height: calc(100vh - 130px);
  padding: 15px;
  background-color: #f8f8f8;
}

.cententBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}

.cententBoxTitle {
  width: 100%;
  display: flex;
  align-items: center;
  margin-block: 15px;
}

.cententBoxTitleText {
  width: 23%;
}

.cententBoxTitleInput {
  width: 77%;
}

.itemPicker {
  width: 35%;
}

// .itemPicker ::v-deep .el-input__inner{
//   width: 100px !important;
// }
.wxhumanResourceAnalysisBottom {
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
}

.bottomItem {
  width: 33%;
  height: 100%;
  text-align: center;
  color: #000;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomItemImg {
  width: 40px;
  padding: 3px;
  border-radius: 10px;
  background-color: #25a2fe;
  margin-top: 10px;
}

.cententTabs {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cententTabsItem {
  padding: 5px 0;
  margin-right: 15px;
}

.tabsAction {
  border-bottom: 2px solid #25a2fe;
  color: #25a2fe;
}

.cententBoxContentText {
  width: 100%;
  text-align: center;
  color: #25a2fe;
  padding: 10px 0;
}

#div_mobilizationTotalNumber {
  width: 100%;
  height: 300px;
}

#div_postInputDistribution {
  width: 100%;
  height: 300px;
}

#LDLQXecharts {
  width: 100%;
  height: 300px;
}

.tableCentent {
  width: 100%;
  margin-top: 20px;
}



.wxVideoTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 4%;
}

.wxVideoTitleInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  height: 40px;
  background-image: url(../../../assets/visualization/humanResourceAnalysis/input.png);
  background-size: 100% 100%;
  // color: #e2e2e2;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Microsoft YaHei", Arial, sans-serif !important;

  background-color: rgba(29, 102, 189, .16);
}

.wxVideoTitleInputIcon {
  font-size: 20px !important;
}

.sxtImageUrl {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.sxtImageUrl img {
  width: 100%;
}

.bzxsxtImageUrl {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.bzxsxtImageUrl img {
  width: 100%;
}

.wxVideoTitleItem {
  width: 100%;
  padding: 15px 4%;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 999;
  // background-color: rgba(29, 102, 189, .16);
}


.itemTextLiBox {
  width: 100%;
  height: 70vh;
  background-image: url(../../../assets/visualization/humanResourceAnalysis/inputItem.png);
  background-size: 100% 100%;
  padding: 10px;
  // background-color: rgba(29, 102, 189, .16);
}

.itemTextLi {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow-y: scroll;
}

.itemText {
  width: 100%;
  padding: 10px;
  // color: #e2e2e2;
  font-size: 13.997px !important;
  font-weight: 500 !important;
}

.active {
  background-color: rgba(29, 102, 189, .16);
}
</style>
